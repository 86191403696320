// .inspectionReport footer
& {
    border-top: 1px solid map-get($theme-colors, misc-grey-light);
    padding-top: 5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 90rem;
    margin: 5rem auto;
    width: 100%;

    h2 {
        color :black;
        font-weight: bold;
    }

    .logo {
        width: 9rem;
        background-image: url('../../../assets/CFITireLogo-Mark.png');
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        height: 9rem;
        margin-right: 2rem;
    }

    .footer-info {
        display: grid;
        grid-template-columns: auto 1fr 1fr;
        margin: 4rem;
        font-size: 1.5rem;
        grid-column-gap: 2rem;
        line-height: 1.4;

        > section {
            display: flex;
            flex-direction: column;
            justify-content: center;
        }

        .contactDetails {
            em {
                color: #808285;
                font-style: normal;
                font-weight: bold;
                display: inline-block;
                margin-right: 2rem;
            }
        }
    }

    > a {
        max-width: 90rem;
        justify-content: center;
        text-align: center;
        background-color: $light-grey;
        width: 100%;
        color: black;
        margin: 1rem;
        padding: 1rem;
        border-radius: 3rem;
        font-weight: bold;
        text-transform: none;
        font-size: 1.6rem;
    }
}