.axlesList {
    .axle {
        > .name {
            display: block;
            width: 100%;
        }

        .subjects {
            display: grid;
            grid-template-columns: 1fr auto 1fr;
            justify-content: center;
            align-items: center;
            margin: 2rem auto;

            span {
                letter-spacing: -0.024em;
                font-size: 1.5rem;
                color: #707070;
                margin: 0 1rem;
            }

            button {
                background-color: #fff;
                border: 3px solid grey;
                border-radius: .75rem;
                height: 65px;
                width: 32px;
                white-space: nowrap;
                text-indent: -9999px;
                margin: 0 5px;
                background-size: 1.7rem;
                background-repeat: no-repeat;
                background-position: center;

                &.complete {
                    border-color: #75EA66;
                    background-image: url('../../assets/check-green.svg');
                }

                &.active {
                    border-color: #007AFF;

                    &.complete {
                        background-image: url('../../assets/check-blue.svg');
                    }
                }

                &.addSubject {
                    background-color: rgba(118,118,118,.24);
                    background-image: url('../../assets/sfpro-plus-circle.png');
                    background-size: 1.8rem;
                    background-position: center;
                    background-repeat: no-repeat;
                    border: none;
                }
            }

            .subject-axles-left {
                text-align: right;
            }
        }
    }
}
