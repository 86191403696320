.equipmentDetail {
    h1 {
        margin-bottom: 0;
    }

    .category-trail {
        margin-top: .5rem;
        margin-left: $content-margin;
    }

    .image-placeholder {
        height: 100%;
    }

    .submitted {
        background-image: url("../../assets/check.svg");
        height: 1.5rem;
        width: 1.5rem;
        background-size: contain;
    }

    &--head {
        display: grid;
        grid-template-rows: 15rem auto;
        margin: 0 $content-margin;
        grid-gap: 1.4rem;
        
        .details {
            width: 100%;
            margin-left: 0;
        }

        @include breakpoint(375px){
            grid-template-rows: unset;
            grid-template-columns: 15rem 1fr;
        }

        @include breakpoint(600px){
            grid-template-columns: 15rem 1fr 1fr;
        }

        .photo {

            > button {
                border-radius: 2rem;
                width: 100%;
                object-fit: cover;
                object-position: 50% 30%;
                overflow: hidden;
                border: unset;
                padding: 0;
                height: 100%;

                @include breakpoint(375px){
                    height: 9.6rem;
                }

                img {
                    height: 100%;
                    border-radius: 2rem;
                    object-fit: cover;
                    object-position: 50% 30%;
                }
            }
        }

    }
}
