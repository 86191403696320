.app-header {
    color: #007AFF;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    // margin-left: -1.5rem;
    // margin-right: -1.5rem;
    position: sticky;
    top: 0;
    padding: 1.2rem;
    background: map-get($theme-colors, slate);
    z-index: map-get($z-indices, app-header);

    > * {
        flex-basis: auto;
    }

    > .title {
        @extend .title-divider;
    }

    > :not(.title) {
        color: inherit;

          &.icn-plus {
            font-weight: bold;
            text-align: right;
            margin-left: auto;
        }
    }

    > button:not(.icn-back) {
        font-weight: bold;
        text-align: right;
    }

    a, button, .button {
        text-decoration: none;
        font-size: 1.7rem;
        letter-spacing: -0.024em;
        display: flex;
        flex-direction: row;
        justify-content: center;
        line-height: 1.1;
        align-items: center;
        background-color: transparent;

        &.icn-back:before {
            content: '';
            display: block;
            width: 1.7rem;
            height: 2.6rem;
            background-image: url('../../assets/blue-chevron-left.png');
            background-size: contain;
            margin-right: .5rem;
            background-repeat: no-repeat;
        }

        &.icn-plus:after {
            content: '';
            display: block;
            width: 2rem;
            height: 2rem;
            background-image: url('../../assets/plus-sfpro.png');
            margin-left: 1rem;
            background-size: contain;
            background-repeat: no-repeat;
        }
    }
}
