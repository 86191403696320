.lastKnownLocation {
    // margin: 2rem 2rem 0;
    font-size: 1.5rem;
    padding-bottom: 2rem;
    border-bottom: solid 1px rgba(60,60,68,.3);
    grid-column: 1 / -1;
    display: flex;
    flex-direction: row;

    > a {
        margin-left: auto;
    }

    @include breakpoint(600px){
        grid-column: unset;
        border-bottom: unset;
        display: flex;
        flex-direction: column;
        padding: 1rem 5rem 1rem 2.1rem;
        background-color: #fff;
        border-radius: 1rem;
        margin-bottom: auto;

        > a {
            margin: 0 auto 0 0;
        }
    }

    strong {
        color: #707070;
        font-weight: bold;
        display: inline-block;
        margin-right: 2rem;
    }
}