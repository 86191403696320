.condition-selector .inner {
    display: flex;
    flex-direction: row;
    justify-content: end;
    align-items: center;
    @extend .outer-margin;

    label {
        font-size: 1.5rem;
        color: black;
        flex: auto;
        flex-basis: 50%;
    }

    button {
        background: none;
        margin-right: 1rem;
        font-size: 1.7rem;
    }

    .modal {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: #000000c0;
        z-index: map-get($z-indices, condition-selector-modal);
        display: flex;
        flex-direction: column;
        justify-content: center;

        padding: 10vh 10vw;

        button {
            background-color: $link-blue;
            color: white;
            padding: 1rem;
            border-radius: 1rem;
        }
    }
}
