.geo {
    display: flex;
    flex-direction: row;
    margin-right: $content-margin;

    .coords {
        flex: auto;
    }

    .locate {
        flex: 0 0 1.7rem;
        background: url('../../assets/map-marker.svg') no-repeat center;
        background-size: contain;
        margin: 0 0 0 auto;
        text-indent: -9999rem;
    }
}
