.equipmentEdit {
    .topLevelCategory {
        font-weight: bold;
    }

    .react-autosuggest__container--open {
        .react-autosuggest__input {
            margin-bottom: 0;
            border-bottom-left-radius: unset;
            border-bottom-right-radius: unset;
        }

        .react-autosuggest__suggestion {
            &--highlighted {
                color: black;
            }

            font-size: 1.7rem;
        }

        .react-autosuggest__suggestions-container {
            background-color: white;
            padding: 1rem 1.5rem;

            border-bottom-left-radius: 1rem;
            border-bottom-right-radius: 1rem;
        }
    }
}
