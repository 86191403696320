.equipmentIcons {
    & {
        display: flex;
        flex-wrap: wrap;
        grid-gap: 3.2rem 1.2rem;
        margin-bottom: 2rem;
    }


    > li .image {
        background-size: contain;
    }

    $categories: 
        'harvest', 
        'tillage', 
        'planting', 
        'tractors', 
        'hayForage', 
        'chemicalApplciators', 
        'manureSpreaders', 
        'agTrailers', 
        'semis',
        'construction',
        'miscEquipment',
        'tractors'
    ;

    @each $category in $categories {
        .#{$category} {
            .image {
                background-image: url('../../assets/icons/#{$category}.svg');
            }
        }
    }


    li {
        border: none;
        border-radius: 2rem;
        margin: 0;
        position: relative;
        color: black;
        justify-content: space-between;
        padding: 1.5rem 0.8rem;
        font-size: 1.7rem;
        letter-spacing: -0.024em;
        background-color: white;
        text-decoration: none;
        align-items: center;
        width: 8rem;
        height: 8rem;

        a {
            display: block;
            width: 100%;
            height: 100%;
        }

        .name {
            top: calc(100% + .6rem);
            position: absolute;
            letter-spacing: 0.025em;
            font-size: 1.2rem;
            color: rgba(60,60,67, 0.6);

            &.long-name {
                display: none;

                @include breakpoint(1024px){
                   display: inline;
                }
            }


            &.short-name {
                display: inline;

                @include breakpoint(1024px){
                   display: none;
                }
            }
        }

        .count {
            position: absolute;
            right: 0.7rem;
            bottom: 0.7rem;
            font-size: 1.4rem;

            /*
            width: 2.4rem;
            padding: 0 0.1rem 0.2rem 0;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 2.4rem;
            border-radius: 50%;
            font-size: 1.4rem;
            border: 2px solid white;
            color: black;

            &.Nominal {
                background-color: green;
                color: white;
            }
            &.Warning {
                background-color: #FFE200;
            }
            &.Critical {
                color: white;
                background-color: #FF0000;
            }
            */
        }

        .image {
            background-position: center;
            background-repeat: no-repeat;
            display: block;
            background-size: contain;
            width: 100%;
            height: 100%;
        }
    }
}