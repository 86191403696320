// .inspectedEquipment
& {
    border-top: 1px solid map-get($theme-colors, misc-grey-light);
    padding-top: 5rem;
    margin-top: 5rem;

    h2 {
        padding: 0;
    }

    h3 {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        font-weight: bold;
        color: black;
        font-size: 3rem;
        margin: 2rem 0;
        padding: 0;
    }

    div.header {
        background-color: $light-grey;
        color: $dark-grey;
        margin: 3rem 0 0;
        padding: 0.5rem 0;
        border-radius: 1rem;
        font-weight: bold;
        text-transform: none;
        font-size: 1.6rem;
        border: none;
        display: flex;
        justify-content: center;
    }

    .asset {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-column-gap: 4rem;
        margin-bottom: 4rem;
        grid-row-gap: 3rem;

        .vehicleAttributes {
            display: grid;
            // grid-template-columns: repeat(2, 1fr);
            grid-column-gap: 3rem;

            h3 {
                grid-column-start: 1;
                grid-column-end: 3;
            }

            dl {
                display: grid;
                grid-template-columns: repeat(2, auto);
                grid-row-gap: .6rem;
                font-size: 1.8rem;
                margin-bottom: auto;

                dd {
                    font-weight: bold;
                    color: $dark-grey;
                }

                &.notes {
                    grid-column-start: 1;
                    grid-column-end: 3;
                    display: flex;
                    flex-direction: row;
                    align-items: start;
                    margin-top: 2.5rem;

                    dd {
                        padding-right: 1rem;
                    }
                }
            }
        }

        .subject {
            border-radius: 3rem;
            background-color: $light-grey;
            padding: .5rem 2rem 2rem;
            padding: 2rem;

            h3 {
                justify-content: center;
                background-color: $light-grey;
                color: black;
                margin: 1rem 0 2rem;
                padding: 0.5rem 0;
                border-radius: 3rem;
                font-weight: bold;
                text-transform: none;
                font-size: 1.6rem;
            }

            dl {
                display: grid;
                grid-template-columns: 18rem auto;
                font-size: 1.7rem;

                &:nth-last-child(n+2){
                    margin-bottom: 2rem;
                }

                dt {
                    font-weight: bold;
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-start;
                    align-items: center;
                }

                &.subjectAttributes {
                    grid-template-columns: auto 1fr;
                    grid-row-gap: .5rem;

                    dd {
                        margin-left: 2rem;;
                    }

                    dt {
                        color: #808285;
                    }
                }

                &.subjectInspectionResults {
                    .severity {
                        background-size: 50% 50%;
                        background-repeat: no-repeat;
                        background-position: center;
                        border: unset;

                        &.Critical,
                        &.Poor {
                            background-image: url('../../../assets/white-cross.svg');                            
                            background-color: #ff0000;
                        }

                        &.Nominal,
                        &.Good {
                            background-image: url('../../../assets/white-plus.svg');                            
                            background-color: #32d74b;
                            background-size: 60% 60%;
                        }

                        &.Warning,
                        &.Fair {
                            background-color: #ffe232;
                            background-image: url('../../../assets/white-minus.svg');                            
                        }
                    }
                }
            }

            .subjectInspectionResults {
                grid-row-gap: 1rem;
                margin-bottom: 3rem;
            }

            .photos {
                grid-column-start: 1;
                grid-column-end: 3;
                display: grid;
                grid-template-columns: repeat(3, 1fr);
                grid-column-gap: 1rem;
                grid-row-gap: 2rem;

                img {
                    width: 16rem;
                    aspect-ratio: 1;
                    object-fit: cover;
                }
            }
        }
    }

    .axlesList {
        .axle {
            > .name {
            }

            .subjects {

                span {
                }

                button {
                    background-color: #fff;
                    width: 34px;
                    
                    &.condition{
                        &-Good {
                            border-color: #32d74b;
                            background-image: url('../../../assets/green-plus.svg')
                        }

                        &-Fair {
                            border-color: #ffe232;
                            background-image: url('../../../assets/yellow-minus.svg')
                        }

                        &-Poor {
                            border-color: #ff0000;
                            background-image: url('../../../assets/red-cross.svg')
                        }
                    }
                }

                .subject-axles-left {
                }
            }
        }
    }
}