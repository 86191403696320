$fleet-row-height: 6rem;

.fleetListing {
    h2 {
        padding: 0;
        margin-left: 1.5rem;
        margin-bottom: 1.5rem;
    }

    .subcat {
        margin-bottom: -1rem;
        position: relative;
        top: -1rem;
        
        h3 {
            margin-bottom: .5rem;
        }
    }

    .backToFleetList {
        margin: 1rem 0 2rem 1.5rem;
        display: block;
    }

    // .details, 
    // input[type="search"] {
    //     margin-left: 1.5rem;
    //     width: calc(100% - 3rem);
    // }


    .fleet {
        .table-container {
            margin-bottom: 1.2rem;
        }
    }

    .equipment-list {
        > li {
            margin: 0 !important;

            &.isSelected {
                padding-right: 0;

                a.row {
                    grid-template-columns: 7rem 1fr 7.5rem 4.5rem;
                    color: white;

                    > span,
                    .name, 
                    .model-year {
                        color: white;
                    }

                    .severity {
                        // margin-right: 1rem;
                    }
                }
            }

            a.row {
                padding: 0 0.7rem 0 0;
                margin: 0;
                border-radius: 0;
            }

            .model-year {
                color: $dark-grey;
            }
        }

        .asset-photo {
            height: $fleet-row-height;
            margin-right: 1rem;

            &.empty {
                background-size: 4.5rem;
                background-repeat: no-repeat;
                background-position: center;
                filter: grayscale(1);

                $categories: 
                    'harvest', 
                    'tillage', 
                    'planting', 
                    'tractors', 
                    'hayForage', 
                    'chemicalApplciators', 
                    'manureSpreaders', 
                    'agTrailers', 
                    'semis',
                    'construction',
                    'miscEquipment',
                    'tractors'
                ;

                @each $category in $categories {
                    &.#{$category} {
                        background-image: url('../../assets/icons/#{$category}.svg');
                    }
                }


            }

            &.loaded {
                overflow: hidden;

                > img {
                    object-fit: cover;
                }
            }
        }

        .row {
            display: grid;
            grid-template-columns: 7rem 1fr 7.5rem 3rem ;
        }
    }
}
