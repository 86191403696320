.dialog {
    position: fixed;
    z-index: map-get($z-indices, client-detail-dialog);
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #00000050;

    .clickRegion {
        position: absolute;
        z-index: map-get($z-indices, client-detail-dialog-click-region);
        width: 100%;
        height: 100%;
    }

    main {
        position: relative;
        z-index: map-get($z-indices, client-detail-dialog-main);
        background-color: white;
        border-radius: 1.5rem;
        padding: 2rem;
        width: 80%;
        height: 350px;
        max-width: 85rem;
        margin: 7rem auto;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;

        strong {
            font-size: 1.8rem;
        }

        span {
            text-align: center;
            padding-top: 1.5rem;
            font-style: normal;
            font-size: 1.5rem;
            color: $dark-grey;
        }

        textarea {
            background-color: #f2f2f7;
            height: 14rem;
        }

        button {
            color: white;
            border-radius: 0.8rem;
            padding: 1rem 1.2rem;
            background: #0672de;
            font-weight: bold;
            width: 10rem;

            &:disabled {
                background: $dark-grey;
                color: #f2f2f7;
                cursor: not-allowed;
            }
        }
    }
}
