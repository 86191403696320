// White Details section - containing specs (Vin, date)
.details,
.assetDetails {
    background-color: white;
    text-align: left;
    padding: 1.7rem 5rem 1.7rem 2.1rem;
    border-radius: 1.3rem;
    letter-spacing: -0.024em;
    font-size: 1.5rem;
    display: flex;
    flex-direction: column;
    position: relative;

    dl {
        display: grid;
        grid-template-columns: auto 1fr;
    }

    h2 {
        padding-top: 0;
    }

    dt {
        float: left;
        clear: left;
        padding-right: 1em;
        color: #707070;
        font-weight: 700;
    }

    dd {
        display: block;
        vertical-align: top;

        &:nth-last-child(n+2){
            margin-bottom: 15px;
        }

        * {
            display: inline-block;
        }
    }

    button {
        background: none;
        border: none;
        font: inherit;
        padding: 0;
    }

    > a, button.edit {
        position: absolute;
        right: $content-margin;
        bottom: 2rem;
        color: transparent;
        display: block;
        width: 1.7rem;
        height: 1.7rem;
        background-image: url('../../assets/pencil.png');
    }

    a {
        font-size: inherit;
    }
}

.details.assetDetails {
    dd {

        &:nth-last-child(n+2){
            margin-bottom: .7rem;
        }
    }
}
