.table-container {
    .actions {
        margin: 0;
        left: unset;
        border: none;

        &:first-child {
            border-bottom: 1px solid rgba(60, 60, 67, 0.2);
        }

        &:not(:first-child){
            border-top: 1px solid rgba(60, 60, 67, 0.2);
        }

        button, a {
            margin-left: 1.5rem;
            padding-left: 0.5rem;
        }

        button + button,
        a + button,
        button + a,
        a + a {
            border-top: 1px solid rgba(60, 60, 67, 0.2);
        }

        .add-action {
            display: flex;
            flex-direction: row;
            padding: 1.35rem 0 1.15rem 0.5rem;
            font-size: 1.7rem;

            &:before {
                content: '';
                display: inline-block;
                width: 1rem;
                height: 1rem;
                background-size: 1.2rem;
                background-repeat: no-repeat;
                background-position: center;
                background-image: url('../../assets/plus-sfpro.png');
                margin-right: 1rem;
                border: 2px solid $link-blue;
                border-radius: 50%;
                padding: .5rem;
                position: relative;
            }
        }
    }
}

.groupedList {
    display: flex;
    flex-direction: column;

    .location, .dueDate, .inspectionDates {
        font-size: 1.5rem;
    }

    .location {
        margin-left: auto;
    }

    .checkbox {

        label {
            display: flex;
            justify-content: space-between;
            align-items: center;
            cursor: pointer;

            span {
                font-size: 1.7rem;
                color: black;
            }

            input {
                appearance: none;
                display: block;
                background-color: rgba(118,118,118,.24);
                width: 5rem;
                height: 3rem;
                border-radius: 2rem;
                position: relative;
                cursor: pointer;
                transition: all .15s ease-in-out;

                &:after {
                    content :'';
                    display: block;
                    background-color: white;
                    border-radius: 50%;
                    width: 2.5rem;
                    height: 2.5rem;
                    position: absolute;
                    left: 2px;
                    top: 3px;
                    transition: all .15s ease-in-out;
                }
            }
        }

        &.checked {
            label {
                input {
                    background-color: #34C759;

                    &:after {
                        left: 2.3rem;
                    }
                }
            }
        }
    }

    .row, .no-results {
        font-size: 1.7rem;
        letter-spacing: -0.024em;
    }

    .no-results {
        height: 25vh;
        text-align: center;
        padding-top: 11vh;
        font-size: 2rem;
    }

    a, a:visited {
        color: black;
    }

    li.letterHeading {
        background: #E9E9EB;
        padding: 0.5rem 1.5rem;
        font-weight: bold;
        border: none;
        font-size: 1.5rem;
    }

    li:not(.letterHeading) {
        margin: 0 0 0 1.5rem;
        padding-right: 1.5rem;

        &+:not(.letterHeading) {
            border-top: 1px solid rgba(60, 60, 60, 0.25);
        }
        .row {
            padding: 0.55rem 0.7rem 0.65rem 0;
            align-items: center;
        }
    }

    li.item {
        width: 100vw;
        display: block;
        border-bottom: none;
        position: relative;

        .row {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
        }
    }

    li.item + li.item {
        &:before {
            content: '';
            display: block;
            width: calc(100% - 4rem);
            height: 0;
            position: absolute;
            top: 0;
        }
    }

    .noResults {
        color: $dark-grey;
        display: flex;
        font-size: 1.7rem;
        flex: 100%;
        flex-direction: column;
        align-self: center;
        justify-content: center;
        width: 100%;
        text-align: center;
        background: white;
    }
}

li.isSelected {
    &.client {
        padding-right: 0.5rem;
        margin-left: 0.8rem;

        a.row {
            padding-right: 0.6rem;

            > *:first-child {
                margin-left: 1rem;
            }
        }
    }

    &.employee {
        padding-right: .5rem;
        margin-left: 0.8rem;

        a.row {
            padding-right: 1.5rem;

            > *:first-child {
                margin-left: 1rem;
            }
        }
    }

    .row {
        background-color: $link-blue;
        margin: 0.9rem 0;

        > span {
            color: white !important;
        }
    }
}
