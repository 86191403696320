
.assignmentDetail, 
.assignmentsList {
    .inputWrapper .field.radio label {
        min-width: 9rem;
        @include breakpoint(1024px){
            padding: 0;
        }
    }

    .filters {
            width: calc(100%);
        @include breakpoint(1023px, max){
            margin: 1rem auto 1rem 0;
        }
    }


    .groupedList {
        & > li .row {
            display: grid;

            .name .errored {
                @extend .errorIndicator;
            }
        }
    }

    .heading {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        flex-wrap: wrap;

        .errored {
            @extend .errorIndicator;
            height: 4rem;
            width: 4rem;
        }
    }

    .half-pane {
        // display: flex;
        // flex-wrap: wrap;

        .details {
            margin: 0 $content-margin 1.5rem;
        }
        
        .status {
            margin: 0 $content-margin;

            select {
                width: 100%;
            }
        }

        > * {
            flex: 1 auto;
            // margin: 0 !important;
            // min-width: 40rem;
        }

        .notes {
            .field {
                height: 100%;

                label {
                    display: flex;
                    flex-direction: column;
                    height: 100%;

                    button {
                        flex: 1;
                        margin-bottom: .5rem;
                    }
                }

            }
        }

        .status {
            h2 {
                margin: 0;
                padding-left: 0;
            }

            .assignmentStatus {
                margin-left: 0;
                margin-right: 0;
            }
        }
    }

    .equipmentIcons {
        margin: 0 2rem 2rem;
    }
}


.newAssignment {
    label, select, .DayPickerInput {
        margin-left: $content-margin;
        width: calc(100% - #{2 * $content-margin});
    }
}

.assignmentDetail {
    .summary {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;

        .empty {
            text-align: center;
            flex: 1 0 100%;
            font-size: 1.7rem;
            margin: 5rem 0;
            color: $dark-grey;
        }
    }

    .groupedList > li .row {
        justify-content: flex-end;
    }

    .link a {
        color: $link-blue;
    }

    section.heading {
        display: grid;
        grid-template-columns: 45% 45%;
        justify-content: space-between;

        .status, .summary {
            grid-column-start: 1;
            grid-column-end: 2;
        }

        .inspectionsTable, .share {
            grid-column-start: 1;
            grid-column-end: 3;

        }

        .plus {
            border-radius: 1.3rem;
            margin: 0;
            height: 100%;
        }

        .DayPickerInput input {
            background-color: #e3e3e9;
            border-radius: 0.5em;
            padding: 0.25em;
        }
    }

    .assignmentStatus {
        margin: 0 $content-margin;
        width: calc(100% - #{2 * $content-margin});
        border: none;
    }
}

.assignmentsList {
    .addAssignment.dialog {
        // TODO: I think this rule can be removed as it is no longer used (FH:NOTE:JRB 20220114)

        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background-color: rgba(map-get($theme-colors, misc-grey), 0.8);
        z-index: map-get($z-indices, add-assignment-dialog);

        display: flex;
    }

    .dateFilter .filter-input {
        display: flex;
        background-color: $text-input-back;
        padding: .5rem 1rem;
        margin: 0 $content-margin;
        border-radius: 1rem;
        margin: 1rem 0 0;

        @include breakpoint(1024px){
            margin: 0 1rem;
        }
    }

    .groupedList.assignments > li {
        &.assignment {
            &.isSelected {
                padding: 0;
                margin: 0;

                .row {
                    padding: 0.55rem 1rem 0.65rem .8rem;
                    border-radius: 1.5rem;
                    margin: 1rem .5rem 1rem .8rem;

                    > div {
                        padding: 0;
                        margin-right: .5rem;
                        margin-left: .5rem;
                        color: white;

                        > span {
                            color: white;
                        }
                    }

                    .whole-row {
                        margin-left: 0;
                        margin-right: 0;
                    }
                }
            }
        }

        .row {
            grid-template-columns: 1fr;
            margin: 0;
            padding: 0;
            border-radius: unset;

            @include breakpoint(1024px){
                grid-template-columns: 1fr 1fr;
            }

            .row-section:nth-child(2){
                display: none;
                
                @include breakpoint(1024px){
                   display: flex;
                }
            }

            .whole-row {
                grid-column: 1 / -1;
                display: flex;
                padding: 1.2rem 0;

                > *:first-child {
                    margin-right: auto;
                }
            }

            .row-section {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 1.5rem;
                width: 100%;
                
                .name {
                    display: flex;
                    align-items: center;
                }

                &:first-child {
                    @include breakpoint(1024px){
                        border-right: 1px solid rgba(60, 60, 60, 0.25);
                    }
                }
            }

            .dueDate {
                margin-right: auto;
            }

            .severity {
                margin-left: 1.5rem;
            }

            @include breakpoint(1024px, max) {

                .dueDate {
                    border-left: none;
                }
            }

            .location {
                letter-spacing: -0.024em;
                color: $text-grey;
                justify-self: end;

                @include breakpoint(1023px, max){
                    margin-left: auto;
                }
            }

            .inspectionDates {
                color: $text-grey;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    } 

    &.hasDetail {
        .groupedList.assignments > li .row {

            .dueDate {
                border-left: none;
            }
        }

        .dateFilter .filter-input {
            width: 100%;
            margin: .5rem 0;

            .DayPickerInput {
                width: 100%;
                margin: 0.25rem 0;
            }
            input {
                margin: 0;
                width: 100%;
            }
        }
    }

    @include breakpoint(1024px, max) {
        .dateFilter .filter-input {
            width: 100%;

            .DayPickerInput {
                width: 100%;
                margin: 0.25rem 0;
            }
            input {
                margin: 0;
                width: 100%;
            }
        }
    }

    header {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin: 0 $content-margin;

        h1 {
            margin: 0;
        }
    }

    .dateFilter {
        font-size: 1.5rem;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        padding: 0 0 1rem 0;
        margin: 0 $content-margin;

        .label {
            font-weight: bold;
            color: $text-grey;
        }

        input {
            background-color: transparent;
            padding: .5rem .5rem;
            border-radius: .5rem;
  
        }

        button {
            background: none;
            position: relative;
            padding: 0 0 0 1rem;
        }
    }
}

.assignmentDetail {
    .dialog {
        main {
            width: 60rem;
            height: auto;
            display: flex;
            flex-direction: column;
            align-items: start;
            justify-content: start;

            h2 {
                margin: 0;
            }

            form {
                width: 100%;

                label > span {
                    text-align: left;
                }

                .namedInputIsPermanentContact {
                    width: 50%;
                }
            }
        }

        input {
            background-color: $light-grey;
        }

        .buttons {
            margin-left: $content-margin;
            margin-top: 2rem;

            .cancel {
                background-color: $light-grey;
                color: $dark-grey;
            }

            button + button {
                margin-left: 1rem;
            }
        }

        footer {
            margin-left: $content-margin;
            margin-right: $content-margin;
            margin-top: 2.5rem;
            padding-top: 2.5rem;
            border-top: 2px solid $light-grey;

            button {
                width: auto;

                &.risky {
                    border: 2px solid map-get($theme-colors, red);
                    background-color: transparent;
                    color: map-get($theme-colors, red);
                    font-weight: normal;
                }
            }
        }
    }

    .inspectionsList {
        .row {
            grid-template-columns: 3fr 4fr 1fr auto;
        }
    }

    .shareHeadingSubtitle {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-right: $content-margin;
    }

    .assignmentSharesList {


        .isPrimaryContact {
            .row {
                .addressParts {
                    * {
                        font-weight: bold;   
                    }
                }
            }
        }

        .row {
            grid-template-columns: 2fr 1fr;
            margin: 0.75rem 0;

            button {
                background: none;
                cursor: pointer;
                font-size: inherit;
            }

            .share-buttons {
                display: flex;
                flex-direction: row-reverse;

                > * {
                    margin-left: 2rem;   
                }
            }

            .reshare, .unshare, .share, a {
                background: none;
                cursor: pointer;
                font-size: inherit;
                color: $link-blue;
            }

            .addressParts {
                display: flex;
                flex-direction: row;

                .name:not(:empty) + .email,
                .email + .isTemp {
                    padding-left: 4rem;
                }

                .email {
                    color: $dark-grey;
                }
            }

            .isTemp:before {
                content: 'TEMP';
                display: inline-block;
                font-size: 1.25rem;
                font-weight: bold;
                background-color: $light-grey;
                color: $text-grey;
                padding: 0.25rem 1.25rem;
                border-radius: 1rem;
            }

            .unshare {
                color: map-get($theme-colors, red);
            }
        }
    }
}
