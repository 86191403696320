.clientDetail {
    .details {

        .changeRequestPanel {
            $separation-space: 2rem;
            margin-top: $separation-space;
            padding-top: $separation-space;

            .indicator {
                @extend .changeRequestIndicator;

                &:before {
                    margin-left: 0;
                    margin-right: 1rem;
                }
            }

            border-top: 2px solid $light-grey;
            display: flex;
            flex-direction: row;
        }
    }

    .groupedList.locations.whitebars .no-results {
        display: none;
    }

    .equipmentIcons {
        margin-left: 1.5rem;
        margin-right: 1.5rem;
    }

    h2 {
        padding-left: 0;
    }

    .metadata {
        display: flex;
        margin: 0 $content-margin;
        font-size: 1.7rem;
        flex-direction: column;

        dl {
            display: flex;
            flex-direction: row;

            dt {
                font-weight: bold;
                color: $dark-grey;
            }

            dt + dd {
                padding-left: 2rem;
            }
        }

        .unassigned {
            font-style: oblique;
        }

        .issue {
            @extend .errorIndicator;
        }
    }

    h2, .links-header {
        display: flex;
        justify-content: space-between;
        margin: 0 1.5rem 1rem;
        padding: 2rem 0 0.5rem 0;

        &.alongside {
            position: absolute;
            right: 0;
            top: 0;
        }
    }

    .no-result {
        font-size: 1.7rem;
        width: 100%;
        text-align: center;
        padding: 4rem 2rem;
        letter-spacing: -0.024em;
        color: #707070;
        display: block;
    }
}

.fleet {
    margin-bottom: 1rem;
    position: relative;

    .details {
        margin: .5rem 1.5rem;
    }
    
    .search-wrapper {
        input {
            margin: 1rem 1.5rem;
        }
    }

    h3 {
        margin: 1rem 0;
    }

    .no-results {
        display: block;
        width: 100%;
        text-align: center;
        font-size: 1.7rem;
        color: #707070;
        margin: 5rem 0;
    }

    .severity {
        margin-left: 1rem;

        &:after {
            display: none;
        }

    }
}
