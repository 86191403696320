.snackbar {
    position: fixed;
    z-index: map-get($z-indices, snackbar);
    top: 5vh;
    right: 1vw;
    width: 40vw;
    height: 80vh;

    overflow: hidden;

    .snack {
        border: 2px solid map-get($theme-colors, misc-grey);
        border-radius: 0.75rem;
        background-color: map-get($theme-colors, slate);
        padding: 2rem;
        font-size: 1.5rem;

        &:nth-child(n+2) {
            margin-top: 1rem;
        }

        &.Information {
            background-color: lighten(map-get($theme-colors, blue), 20%);
        }
        &.Warning {
            background-color: lighten(map-get($theme-colors, yellow), 20%);
        }
        &.Error {
            background-color: lighten(map-get($theme-colors, red), 20%);
        }

        button {
            background: none;
            position: absolute;
            right: 1rem;
            margin-top: -1rem;
        }
    }
}
