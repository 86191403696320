

@mixin breakpoint($size, $constraint: min) {
    @if $constraint == max {
        @media screen and (max-width: $size) {
            @content;
        }
    }

    @if $constraint == min {
        @media screen and (min-width: $size) {
            @content;
        }
    }
}

.mobile-only {
    @include breakpoint(1024px){
        display: none !important;
    }
}

button.plus {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    border-radius: 5px;
    text-align: center;
    background-color: #E3E3E9;
    color: #007aff;
    padding: 1.2rem 1rem;
    margin: 1rem 0 3rem;
    font-size: 1.7rem;
    height: 3.6rem;

    &:before {
        content: '';
        display: inline-block;
        width: .5rem;
        height: .5rem;
        background-size: 1rem;
        background-repeat: no-repeat;
        background-position: center;
        background-image: url('../assets/plus-sfpro.png');
        margin-right: 1rem;
        border: 2px solid $link-blue;
        border-radius: 50%;
        padding: .6rem;
        position: relative;
        bottom: 0rem;
    }
}

.labelWrapper, .notesInputWrapper {
    margin: .5rem 2rem .5rem 1.5rem;

    .error {
        color: map-get($theme-colors, red);
        font-weight: bold;
    }
}

.inputWrapper.filters {
    .labelWrapper, .notesInputWrapper {
        margin: 0;
    }
}

textarea {
    resize: vertical;
}

hr {
    color: rgba(60,60,67,.4)
}

.title-divider {
    flex: 1 0 100%;
    // background-image: linear-gradient(to bottom, black, map-get($theme-colors, slate));
    color: $dark-grey;
    text-align: center;
    font-size: 1.7rem;
    padding: 0 0 0.8rem 0;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 1;
    @extend .outer-margin;

    > span {
        display: block;
        font-weight: inherit;
        padding: 1.6rem;
        background-color: rgb(242, 242, 247);
    }

    &:after{
        content: '';
        position: absolute;
        height: 0px;
        width: calc(100% - 1rem);
        border-top: 1px solid rgba(60,60,67,.2);
        top: calc(50% - 3px);
        z-index: -1;
        left: 0;
    }
}

.outer-margin {
    margin-left: $content-margin;
    margin-right: $content-margin;
}
