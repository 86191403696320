.app-footer {
    border-top: 1px solid $border-grey;
    background-color: white;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 100vw;
    position: fixed;
    bottom: 0;
    left: 0;


    > button {
        font-weight: normal;
        background: none;
        padding: 10px 0;
        flex: 1;
        text-align: center;
        position: relative;

        .icon {
            background-size: contain;
        }
        .count {
            border: 2px solid white;
            color: white;
            background-color: $link-blue;
            font-size: 1.5rem;
            height: 2.5rem;
            font-weight: bold;
            position: absolute;
            left: calc(50% + 0.25rem);
            top: 0.25rem;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        &:nth-child(n+2){
            &:before {
                content :'';
                position: absolute;
                height: 2rem;
                top: 0;
                margin: auto;
                bottom: 2px;
                border-left: 1px solid rgba(60,60,67,.36);
                display: block;
            }
        }
    }

    .home {
        background-image: url('../../assets/home.svg');
        background-repeat: no-repeat;
        text-indent: 100%;
        white-space: nowrap;
        overflow: hidden;
        width: 26px;
        height: 26px;
        display: inline-block;

        &.active {
            background-color: $link-blue;
            background-image: none;
            mask-image: url('../../assets/home.svg');
        }
    }

    .clients {
        background-image: url('../../assets/user.svg');
        background-repeat: no-repeat;
        text-indent: 100%;
        white-space: nowrap;
        overflow: hidden;
        width: 22px;
        height: 26px;
        display: inline-block;

        &.active {
            background-color: $link-blue;
            background-image: none;
            mask-image: url('../../assets/user.svg');
        }
    }

    .employees {
        background-image: url('../../assets/employee.svg');
        background-repeat: no-repeat;
        text-indent: 100%;
        white-space: nowrap;
        overflow: hidden;
        width: 28px;
        height: 23px;
        display: inline-block;

        &.active {
            background-color: $link-blue;
            background-image: none;
            mask-image: url('../../assets/employee.svg');
        }
    }

    .assignments {
        background-image: url('../../assets/icons/assignment.svg');
        background-repeat: no-repeat;
        text-indent: 100%;
        white-space: nowrap;
        overflow: hidden;
        width: 22px;
        height: 24px;
        display: inline-block;

        &.active {
            background-color: $link-blue;
            background-image: none;
            mask-image: url('../../assets/icons/assignment.svg');
        }
    }

    .settings {
        background-image: url('../../assets/settings.svg');
        background-repeat: no-repeat;
        text-indent: 100%;
        white-space: nowrap;
        overflow: hidden;
        width: 25px;
        height: 25px;
        display: inline-block;

        &.active {
            background-color: $link-blue;
            background-image: none;
            mask-image: url('../../assets/settings.svg');
        }
    }
}
