// .inspectionOverview 
& {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 8rem;


    // h2, h3, p, figcaption, li, a, .legend, .annotation {
    //     font-style: italic;
    // }

    .severity {
        background-size: 50% 50%;
        background-repeat: no-repeat;
        background-position: center;

        &.Critical {
            background-image: url('../../../assets/white-cross.svg');
        }

        &.Nominal {
            background-image: url('../../../assets/white-plus.svg');
        }

        &.Warning {
            background-image: url('../../../assets/white-minus.svg');
        }
    }

    .metrics {
        display: grid;
        grid-template-columns: repeat(2, 1fr);

        h2 {
            grid-column-start: 1;
            grid-column-end: 3;
            padding: 0 0 4rem;
        }

        figure {
            margin: 0;

            figcaption {
                font-weight: bold;
                font-size: 1.6rem;
            }
            span {
                font-weight: lighter;
                font-size: 5rem;
            }
        }

        .breakdown {
            grid-column-start: 1;
            grid-column-end: 3;

            display: grid;
            grid-template-columns: repeat(2, 1fr);

            h3 {
                grid-column-start: 1;
                grid-column-end: 3;

                background-color: $light-grey;
                color: black;
                text-align: center;
                margin: 3rem 0 0 0;
                padding: 0.5rem 0;
                border-radius: 1rem;
                font-weight: bold;
                text-transform: none;
                font-size: 1.6rem;
            }

            .legend {
                margin: auto 0;

                > div {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    margin: 1rem 0;

                }
            }
        }
    }

    .equipment {
        li {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            margin: 1rem 0;

            a {
                color: black;
            }
        }
    }
}