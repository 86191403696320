h1 {
    font-size: 3.4rem;
    font-weight: bold;
    margin: 2rem 0 2rem 1.5rem;
}

h2 {
    margin: 0 0 1rem;
    color: black;
    display: flex;
    justify-content: space-between;
    padding: 1.5rem $content-margin;
    font-size: 1.7rem;
    font-weight: 600;
    letter-spacing: -0.024em;
}

h3 {
    color: rgba(60,60,67, .6);
    font-size: 1.3rem;
    text-align: left;
    text-transform: uppercase;
    margin: -.5rem 0 1rem;
    padding-left: 1.5rem;
    display: block;

    span.large {
        font-size: 1.9rem;
        color: #707070;
        font-weight: bold;
        text-align: center;
    }

    &.section-head {

        &:before {
            content: '';
            width: 100%;
            border-top: 1px solid rgba(60,60,67,0.2);
            display: block;
            position: absolute;
            z-index: -1;
            top: 50%;
        }

        span {
            background-color: map-get($theme-colors, slate);
            padding: 0 1rem;
        }
    }
}

main {
    hr {
        margin: 2rem 1.5rem;
    }
}

.table-container {
    background-color: white;
}

    .no-results {
        font-weight: normal;
        font-size: 1.4rem;
        color: rgba(60,60,67,.8);
        display: block;
        margin-bottom: .5rem;
        padding: 0 1.5rem;
    }

.whitebars {
    .row {
        border-radius: 1.5rem;
        margin: 0.5rem 0;
        padding: 0.75rem 1rem;
    }

    &.my-assignments {
        .dueDate {
            color: rgba(60,60,67, .6);
            text-align: right;
            font-size: 1.5rem;
        }
    }

    > li {
        padding: 0;
        border: none;
        color: black;
        display: flex;
        justify-content: space-between;
        font-size: 1.7rem;
        letter-spacing: -0.024em;
        background-color: white;
        text-decoration: none;
        align-items: center;
        background-repeat: no-repeat;
        background-position: calc(100% - 1rem) 50%;
        background-size: 1.4rem;

        > *:first-child {
            flex: 1;
        }

        a, a:visited {
            color: black;
            display: flex;
            justify-content: space-between;
            font-size: 1.7rem;
            letter-spacing: -0.024em;
            text-decoration: none;
            align-items: center;
        }
    }
}

a, a:visited {
    color: $link-blue;
    text-decoration: none;
    font-size: 1.7rem;
    letter-spacing: -0.024em;
}

input[type=search],
input[type=email],
select,
input[type=text],
input[type=password],
textarea,
input[type=number]  {
    background-color: white;
    padding: 1rem 1.5rem;
    width: 100%;
    border-radius: 1rem;
    margin: 1rem 0;
    font-size: 1.7rem;
    letter-spacing: -0.024em;
    color: black;
}

.search-wrapper {
    position: relative;
}

.clearSearch {
    position: absolute;
    margin-top: 1rem;
    right: 2.5rem;
    top: calc(50% - 2.5rem);
    background: none;
    font-size: 1.7rem;
    color: $dark-grey;
}

input[type=file]{
    margin-top: 1rem;
}

input[type=search] {
    background: #E3E3E9;
    padding-left: 4rem;
    color: black;
    background-image: url('../../assets/search.png');
    background-size: 2.5rem;
    background-position: .8rem .9rem;
    background-repeat: no-repeat;
    margin: 0 0 1rem 1.5rem;
    width: calc(100% - 3rem);
}

h1 + input[type=search] {
    margin-top: 0;
}

textarea {
    border: none;
    clear: both;
    display: block;
}

.inputWrapper {
    border-radius: 0.75rem;
    display: flex;
    justify-content: center;
    width: min-content;
    background-color: rgba(118,118,118,.12);
    margin: 1rem auto 2rem;
    padding: 4px;
    overflow: hidden;
    flex-direction: column;

    @include breakpoint(500px){
        flex-direction: row;
        padding: 2px;
    }

    .field.radio {
        display: flex;
        flex-direction: row;
        justify-content: center;
        width: 100%;
        font-weight: 500;
        flex: auto;
        text-align: center;
        transition: all .15s ease-in-out;
        margin: 0;

        &:before {
            opacity: 0;
            transition: opacity .15s ease-in-out;
            display: inline-block;
            color: rgba(118,118,118,.12);
            font-size: 2rem;
            position: relative;
            top: -1px;

            @include breakpoint(375px){
                content: '|';
            }
        }

        &:not(.selected) + .radio:not(.selected):before {
            opacity: 1;
        }

        label {
            cursor: pointer;
            padding: 5px 2rem;
            flex: auto;
            display: flex;
            justify-content: center;
            align-items: center;
            min-width: 10rem;
        }

        &.selected {
            border-radius: 0.75rem;
            background: white;
            font-weight: bold;
            box-shadow: 0px 0px 5px 4px rgba(71, 52, 52, 0.12);
        }

        label {
            color: black;
        }

        input {
            display: none;
        }
    }
}

label {
    font-size: 1.3rem;
    letter-spacing: 0.025em;
    color: rgba(60,60,67,0.6);
}

.actions {
    border: 1px solid $light-grey;
    display: flex;
    flex-direction: column;
    margin: 1rem auto;
    width: 100%;
    position: relative;

    > .disabled {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        * {
            color: $dark-grey;
        }

        .add-action:before {
            background-image: none !important;
            background-color: $dark-grey;
            border-color: $dark-grey !important;
            mask-image: url('../../assets/sfpro-plus-circle.png');
            mask-size: 2.3rem;
            mask-repeat: no-repeat;
            mask-position: center;
            top: -0.1rem;
        }
    }

    button, a, span {
        background-color: white;
        padding: 1rem $content-margin;
        font-size: 1.7rem;
    }

    button, a {
        text-transform: capitalize;
        color: $link-blue;
        flex: auto;
        text-align: left;

        &:not(:first-child) {
            border-top: 1px solid $light-grey;
        }

        &.risky {
            color: red;
        }

        &:disabled {
            color: $dark-grey;
            cursor: not-allowed;
        }
    }
}

.invalid input {
    background-color: rgba($error, 0.3);
}

button {
    cursor: pointer;
}

hr {
    transform: scaleY(.5);
    opacity: .5;
    margin: 1rem 0;
}

button {
    cursor: pointer;
}

hr {
    transform: scaleY(.5);
    opacity: .5;
    margin: 1rem 0;
}

ul.whitebars .addToList {
    a, button {
        color: $link-blue;
        justify-content: center;
        align-items: center;
        appearance: none;
        background-color: transparent;
        display: flex;
        padding: 1.5rem 0;
        letter-spacing: -0.024rem;
        text-decoration: none;
        font-size: 1.7rem;


        &:before {
            content: '';
            display: inline-block;
            width: 1rem;
            height: 1rem;
            background-size: 1.2rem;
            background-repeat: no-repeat;
            background-position: center;
            background-image: url('../../assets/plus-sfpro.png');
            margin-right: 1rem;
            border: 2px solid $link-blue;
            border-radius: 50%;
            padding: .5rem;
            position: relative;
        }
    }
}

.image-placeholder {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: #fff;
    border-radius: 2rem;
}

.severity, .badge {
    background-color: #E3E3E9;
    margin: 0 0 0 auto;
    border: 2px solid white;
    border-radius: 2rem;
    display: flex;
    min-width: 2.4rem;
    justify-content: center;
    align-items: center;
    height: 2.4rem;
    font-weight: bold;
    padding: 0 .1rem .1rem 0;
    width: min-content;
    font-size: 1.4rem;

    span {
        line-height: 0;
        text-align: center;
        padding: 0 0.5rem;
    }

    &.Nominal {
        background-color: map-get($theme-colors, green);
        color: black;
    }
    &.Warning {
        background-color: map-get($theme-colors, yellow);
        color: black;
    }
    &.Critical {
        background-color: map-get($theme-colors, red);
        color: white;
    }
}

.location-edit {
    text-indent: -9999rem;
    display: block;
    width: 1.7rem;
    height: 1.7rem;
    background-image: url('../../assets/pencil.svg');
    background-position: center;
    background-repeat: no-repeat;
    position: absolute;
    right: 2rem;
    bottom: 3rem;
}


.namedInputincludeInReport {
    border-bottom: solid 1px rgba(60, 60, 67, 0.2);
  padding-bottom: 1.5rem;
  margin-bottom: 1.5rem;
}

.errorIndicator {
    display: inline-block;
    text-indent: -9999rem;
    width: 2rem;
    height: 2rem;
    background-image: url("../../assets/exclamation.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    margin-left: 1rem;
}

.changeRequestIndicator:before {
    display: block;
    width: 2.25rem;
    height: 2.25rem;
    margin-left: 2rem;
    content: 'i';
    background-color: map-get($theme-colors, blue);
    border-radius: 0.5rem;
    color: white;
    text-align: center;
    vertical-align: middle;
    font-weight: bold;
    font-size: 1.4rem;
    line-height: 2.25rem;
}


/* clears the 'X' from Internet Explorer */
input[type=search]::-ms-clear {  display: none; width : 0; height: 0; }
input[type=search]::-ms-reveal {  display: none; width : 0; height: 0; }

/* clears the 'X' from Chrome */
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration { display: none; }
