.employeesList {
    .clearSearch {
        margin-top: 1rem;
    }

    .employee {
        span.assignmentCount {
            color: $text-grey;
            font-size: 1.5rem;
        }
    }

    .employeeDetail {
        .groupedList {
            .row {
                display: grid;
                grid-template-rows: auto auto;
                grid-template-columns: 1fr;
                margin: 0;
                padding: 0;
                border-radius: unset;

                @include breakpoint(1024px){
                    grid-template-columns: 1fr auto;
                    grid-template-rows: unset;
                }

                .row-section {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding: 1rem 1.5rem;
                    width: 100%;
                    min-width: 18rem;

                    &:nth-child(2){
                        @include breakpoint(1023px, max){
                           display: none;
                        }
                    }

                    &:first-child {
                        @include breakpoint(1024px){
                            border-right: 1px solid rgba(60, 60, 60, 0.25);
                        }
                    }

                    &:last-child {
                        justify-content: flex-end;
                    }

                    .severity {
                        margin-left: 2rem;
                    }
                }

                .location, .dueDate {
                    color: $text-grey;
                    text-align: right;
                }
            }
        }


        main {
            overflow-y: scroll;
            overflow-x: hidden;
            scrollbar-width: none;
            &::-webkit-scrollbar {
                display: none;
            }
        }

        grid-template-columns: 33% auto;

        .app-header {
            width: 33%;
        }

        .leftPanel {
            padding-right: 1.5rem;
            border-right: 1px solid $light-grey;
        }

        .employeeDetail {
            margin-top: 7rem;
            padding-left: 1.5rem;
        }

            .no-results {
                display: block;
                width: 100%;
                text-align: center;
                font-size: 1.7rem;
                color: #707070;
                margin: 5rem 0;
                height: unset;
                padding-top: unset;
            }


        .details dl {
            display: grid;
            grid-row-gap: .5rem;

            @include breakpoint(1200px){
                grid-template-columns: 14rem auto 10rem auto;
            }

            .field {
                width: 100%;
            }

            input {
                background-color: $light-grey;
                width: 100%;
                height: 3rem;
                border-radius: 1.5rem;
            }

            > * {
                display: flex;
                align-items: center;
                margin: 0;
            }
        }

        .assignmentsList {
            display: flex;
            flex-direction: column;
            font-size: 1.7rem;
            width: 100%;

            &.no-results {
                background-color: #f2f2f7;
                margin: 0;
            }

            li {
                background-image: none;
                display: flex;
            }

            .city {
                text-align: right;
            }
        }

        .actions {
            left: 0;
            width: auto;
        }
    }
}

.employeeDetail {
    .details {
        padding-right: 0;
    }
}
