.image-overlay {
    display: flex;
    flex-direction: column;
    position: fixed;
    justify-content: center;
    align-items: center;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgba(0,0,0, .8);
    height: 100vh;
    width: 100vw;
    z-index: 100;
    opacity: 0;
    pointer-events: none;
    padding-top: 8rem;
    transition: all .35s ease-in-out;

    &.active {
        opacity: 1;
        pointer-events: all;
    }

    .close {
        position: absolute;
        right: 4rem;
        top: 4rem;
        background-color: transparent;
        color: transparent;
        background-image: url('../../assets/white-cross-thin.svg');
        background-repeat: no-repeat;
        background-size: contain;
        height: 3rem;
        width: 3rem;
    }

    &--int {
        max-width: 80rem;
        max-height: 60rem;
        margin: auto;
        width: calc(100% - 8rem);
        height: calc(50% - 4rem);

        .delete-button {
            display: block;
            padding: .8rem 1.5rem;
            border-radius: 1rem;
            background-color: #FF0000;   
            color: white;
            font-weight: 600;
            font-size: 1.7rem;
        }

        img {
            height: 100%;
            border-radius: 2rem;
            margin-bottom: 2rem;
        }
    }
}