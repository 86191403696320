@use "sass:math";

$theme-colors: (
    green: #75EA66,
    yellow: #ffe200,
    red: #ff0000,
    slate: #f2f2f7,
    blue: #007aff,
    misc-grey: rgb(60, 60, 67),
    misc-grey-light: rgba(60, 60, 67, .5),
);

$z-indices: (
    login-wrapper-element: 1,
    app-header: 100,
    rolodex: 100,
    add-assignment-dialog: 999,
    client-detail-dialog: 999,
    client-detail-dialog-click-region: 1000,
    client-detail-dialog-main: 1001,
    condition-selector-modal: 9999,
    settings-logs-modal: 9999,
    loading-pane: 10000,
    snackbar: 10001,
);

$link-blue: map-get($theme-colors, blue);
$dark-grey: #707070;
$error: #d00000;
$light-grey: rgba(118, 118, 128, 0.12);
$border-grey: rgba(60, 60, 60, 0.36);

$text-grey: rgba(map-get($theme-colors, misc-grey), 0.6);
$text-input-back: #e3e3e9;

$responsive-collapse-threshold-aspect-ratio: #{math.div(5, 8)};

$content-margin: 1.5rem;
