.gridTable {
    display: grid;
    justify-content: normal;
    align-items: stretch;

    color: black;
    margin: 0 -15px;
    padding: 0 1.5rem;
    font-size: 1.7rem;
    letter-spacing: -0.024em;
    background-color: white;
    text-decoration: none;
    border-top: 1px solid rgba(60, 60, 67, 0.36);
    border-bottom: 1px solid rgba(60, 60, 67, 0.36);

    > * {
        padding: 1rem 0;
        border-top: 1px solid rgba(60, 60, 67, 0.36);
    }

    .firstRow {
        border-top: none;
    }
}
