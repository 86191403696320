.editClient {
    .deltaInput {
        margin-bottom: 2rem;

        .different {
            margin-left: $content-margin;
            font-size: 1.4rem;
            color: map-get($theme-colors, red);
        }
    }
}
