form {
    .form-header {
        padding-bottom: 1rem;
        border-bottom: 1px solid rgba(60,60,67, .25);
        display: flex;
        justify-content: space-between;
        margin: 0 1.5rem 2rem;

        h2 {
            font-size: 2.3rem;
            letter-spacing: 0.023em;
            font-weight: bold;
        }

        a, button {
            appearance: none;
            color: $link-blue;
        }
    }

    .DayPickerInput {
        width: 100%;

        input {
            width: 100%;
            padding: 1rem 1.5rem;
            margin-top: .5rem;
            border-radius: 1rem;
            font-size: 1.7rem;
        }
    }


    .field {
          &.large-label {
            label {
                color: black;
                font-size: 1.7rem;
            }
        }
    }

    .notesInputWrapper {
        label {
            button {
                margin-bottom: 1rem;
            }
        }
    }

    input {
        &::placeholder {
            color: transparent;
        }
    }

    select {
        appearance: none;
        border: unset;
    }

    .checkbox,
    .includeInReport {

        label {
            display: flex;
            justify-content: space-between;
            align-items: center;
            cursor: pointer;

            span {
                font-size: 1.7rem;
                color: black;
            }

            input {
                appearance: none;
                display: block;
                background-color: rgba(118,118,118,.24);
                width: 5rem;
                height: 3rem;
                border-radius: 2rem;
                position: relative;
                cursor: pointer;
                transition: all .15s ease-in-out;

                &:after {
                    content :'';
                    display: block;
                    background-color: white;
                    border-radius: 50%;
                    width: 2.5rem;
                    height: 2.5rem;
                    position: absolute;
                    left: 2px;
                    top: 3px;
                    transition: all .15s ease-in-out;
                }
            }
        }

        &.checked {
            label {
                input {
                    background-color: #34C759;

                    &:after {
                        left: 2.3rem;
                    }
                }
            }
        }
    }

    label {
        span {
            display: block;
        }
    }

    input.disabled {
        color: rgba(60,60,67, .6);
        background: #E3E3E9;
    }

    button {
        color: $link-blue;
    }
}
