.loading {
    background-color: #ffffffc0;
    position: fixed;
    z-index: map-get($z-indices, loading-pane);
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    .loading--indicator {
        background-size: contain;
        background-image: url('../../assets/cupertino_activity_indicator_selective.gif');
        background-repeat: no-repeat;
        text-indent: -9999rem;
        width: 25%;
        height: 25%;
    }
}
