label {
    .imageInputWrapper {
        margin-top: 1.5rem;
    }
}

.imageInputWrapper {
    @mixin imageTextAtts {
        text-align: center;

        font-size: 1.4rem;
        font-weight: 600;
        letter-spacing: -0.024em;
    }

    &.singleLayout {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        button.removePhoto {
            @include imageTextAtts;
            background: none;
            color: map-get($theme-colors, red);
        }

        label {
            margin: 0 1.3rem 2rem 0;
            display: flex;
            flex-direction: column;
            color: $link-blue;
            font-size: 1.7rem;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            position: relative;
            border-radius: 2rem;

            span {
                @include imageTextAtts;
                display: block;
                margin-top: .8rem;
            }

            .image-placeholder {
                width: 15rem;
                height: 9.6rem;
                font-size: 1.7rem;
                text-align: center;
                // Needs none to propogate click up to label and then back down to input
                pointer-events: none;
            }

            // &:after {
            //     font-weight: bold;
            //     font-size: 3rem;
            //     content: '+';
            //     display: flex;
            //     position: absolute;
            //     right: -1rem;
            //     top: 7.6rem;
            //     background: $link-blue;
            //     border-radius: 50%;
            //     justify-content: center;
            //     align-items: center;
            //     color: white;
            //     line-height: 0.4;
            //     width: 1rem;
            //     padding: 0.7rem 1rem 1.2rem;
            // }

            img {
                width: 15rem;
                height: 9.6rem;
                border-radius: 2rem;
                display: block;
                object-fit: cover;

            }
        }


        &.imageInputWrapper {
            label {
                margin: 0;
            }
        }
    }

    &.multipleLayout {
        label {
            float: left;
            margin: 0 1.3rem 2rem 0;
            display: flex;
            flex-direction: column;
            width: 7.8rem;
            height: 7.8rem;
            border-radius: 2rem;
            background: white;
            color: $link-blue;
            text-align: center;
            font-size: 1.7rem;
            justify-content: center;
            align-items: center;
            cursor: pointer;
        }

        .images-list {
            display: flex;
            flex-wrap: wrap;

            button > img {
                width: 7.8rem;
                height: 7.8rem;
                background: rgba(60, 60, 67, .12);
                border-radius: 2rem;

                object-fit: cover;
                display: block;
                margin: 0 1.3rem 2rem 0;

            }
        }
    }
}
