.login {

    &-wrapper {
        background-image: url('../../assets/bg-web.jpg');
        background-repeat: no-repeat;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        flex: 1;
        position: relative;
        margin: 0 -1.5rem;
        background-size: calc(max(135vh, 100vw));
        background-position: 100% 8%;

        > * {
            position: relative;
            z-index: map-get($z-indices, login-wrapper-element);
        }

        &:before {
            content: '';
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            height: 75vh;
            background-image: linear-gradient(to bottom, rgba(0,0,0, 0), rgba(0,0,0,0.28));
        }

        &:after {
            content: '';
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            background-color: rgba(0, 122,255,.06);
        }

        &--int {
            max-height: 60rem;
            display: flex;
            height: 70vh;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            padding: 0 4rem 5.5rem;
            max-width: 40rem;
            width: calc(95vw - 5rem);
        }
    }

    &-footer {
        position: absolute;
        bottom: 0;
        width: 100%;
        left: 0;
        right: 0;
        height: 5rem;
        background-color: #fff;
        display: flex;
        justify-content: center;


        > img {
            margin-bottom: auto;
            padding: 1.2rem;
            margin-top: -4rem;
            background: white;
            border-radius: 50%;
            max-width: 7.8rem;
        }
    }
}

form.login {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;

    label {
        width: 100%;
        color: #fff;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: .5rem;
        font-size: 1.7rem;

        > input {
            display: block;
            margin-top: .5rem;
            padding: .8rem 1.5rem;
            border-radius: 1rem;
            width: 100%;
        }
    }

    .form-buttons {
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-top: 1rem;

        button {
            margin-top: .5rem;
            color: white;
        }

        .login, .submit-reset {
            font-size: 1.7rem;
            padding: .8rem 2rem;
            border-radius: 1.8rem;
            background-color: #596C36;

            &:disabled {
                cursor: not-allowed;
                color: $dark-grey;
                background-color: darken($dark-grey, 30%);
            }
        }

        .forgot-password, .cancel {
            font-size: 1.5rem;
            background: transparent;
            border: none;
        }


    }

    &:not(.forgot-password) .errorMessage {
        font-size: 1.3rem;
        color: #FFE232;
        background-color: rgba(0,0,0,.5);
        padding: .6rem .8rem;
        letter-spacing: -0.024em;
    }

    &.forgot-password .errorMessage {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: #000000a0;

        .modalContent {
            border-radius: 2rem;
            width: 80vw;
            height: 25rem;
            background-color: white;
            position: absolute;
            left: 10vw;
            top: calc(50vh - 20rem);

            font-size: 2rem;
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            align-items: center;

            span {
                display: inline-block;
                width: 70%;
                text-align: center;
            }

            button {
                border-radius: 1.5rem;
                background: $link-blue;
                color: white;
                font-size: inherit;
                text-align: center;
                display: block;
                width: 11rem;
                height: 6rem;
            }
        }
    }
}
