.clientsList {
    display: flex;
    flex-direction: column;

    .filters {
        margin: 0.75rem 1.5rem;
        width: calc(100% - 3rem);
    }

    .filters {
        margin-bottom: 1.5rem;
    }

    .clearSearch {
        margin-top: 1rem;
    }

    .errored {
        @extend .errorIndicator;
    }

    .changeRequestNotice {
        @extend .changeRequestIndicator;
    }

    &.hasDetail {
        .clients {
            .row {
                grid-template-columns: 1fr 12rem;
                grid-column-gap: 0;

                .location {
                    margin-right: 1rem;
                }

                .assignedTo, .dueDate {
                    display: none;
                }
            }
        }
    }

    .clients {

        .client{

            .row {
                .name {
                    display: flex;
                    flex-direction: row;
                }

                .location {
                    letter-spacing: -0.024em;
                    color: $text-grey;
                    justify-self: end;
                }

                .dueDate {
                    color: $text-grey;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }

                .assignedTo {
                    span {
                        color: $text-grey;
                    }
                
                    .employeeName {
                        color: rgb(60, 60, 60);
                    }
                }
            }

            &.assigned {
                .row {
                    display: grid;
                    padding: 0;
                    margin: 0;
                    grid-template-columns: 1fr;
                    grid-template-rows: auto auto;

                    @include breakpoint(1024px){
                        grid-template-rows: unset;
                        grid-template-columns: 1fr 1fr;
                    }

                    .row-section {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        padding: 1rem;
                        flex-direction: row;

                        &:nth-child(2){
                            @include breakpoint(1023px, max){
                               display: none;
                            }
                        }

                        .dueDate {
                            margin-left: auto;
                            margin-right: 0;
                        }

                        .severity {
                            margin-left: 2rem;
                        }


                        &:first-child {
                            @include breakpoint(1024px){
                                border-right: 1px solid rgba(60, 60, 60, 0.25);
                            }
                        }
                    }
                }
            }

            &:not(.assigned){
                .row {
                    display: grid;
                    grid-template-columns: 1fr  12rem;
                    grid-column-gap: 1rem;

                    * {
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }


                    @include breakpoint(1024px, max) {
                        grid-column-gap: 0;

                        .location {
                            margin-right: 1rem;
                        }

                        .assignedTo, .dueDate {
                            display: none;
                        }
                    }


                    .assignedTo {
                        color: $text-grey;

                        .employeeName {
                            color: black;
                        }
                    }

                    .severity:not(.hasAssignments) {
                        justify-content: end;
                        visibility: hidden;
                    }

                    .location {
                        /** TODO: How much of this is still needed?
                        flex: 1;
                        font-size: 1.5rem;
                        letter-spacing: -0.024em;
                        */
                        text-align: right;
                        color: $text-grey;
                    }
                }
            }
        }
        



        .isSelected .changeRequestNotice:before {
            color: map-get($theme-colors, blue);
            background-color: white;
        }
    }

    .filters {
        min-height: 3.2rem;
    }
}
