.inspections {
    ul {
        li {

            &.status-1,
            &.status-2 {
                background-image: url('../../assets/check.png');

                > a {
                    span.dueDate{
                        color: #707070;
                    }
                }
            }

            > a {
                span.dueDate, span.name {
                    color: #000000;
                }

                span {
                    color: $link-blue;

                    &.errorLabel {
                        color: red;
                        display: block;
                        margin: 0 1.5rem;
                    }
                }
            }
        }
    }
}

        .condition-selector {

            &:after {
                content: '';
                padding-top: .5rem;
                margin: 1rem 1.5rem 1.5rem;
                display: block;
                width: calc(100% - 3.5rem);
                border-bottom: 1px solid rgba(60,60,60,.25);
            }
        }

.inspectionDetail {
    .app-header {
        button:disabled {
            cursor: not-allowed;
            color: $dark-grey;
        }
    }

    .assignmentDetail {
        h1 {
            margin-bottom: 0;
        }

        .assetName {
            color: $text-grey;
            font-size: 1.5rem;
            display: inline-block;
            margin: 1rem 0 1rem $content-margin;
        }

    }

    &--head {
        display: grid;
        grid-template-columns: 10rem 1fr;
        grid-gap: 1.4rem;
        @extend .outer-margin;

        @include breakpoint(375px){
            grid-template-columns: 15rem 1fr;
        }

        > button {
            border-radius: 2rem;
            width: 100%;
            height: 6.5rem;
            object-fit: cover;
            border: unset;
            padding: 0;
            overflow: hidden;

            img {
                height: 100%;
            }

            @include breakpoint(375px){
                height: 9.6rem;
            }
        }
    }


    .section-head {
        margin: 2.5rem 0 1rem;
        text-align: center;
        width: calc(100% - 2rem);
        position: relative;
        display: flex;
        justify-content: center;
        z-index: 1;
    }

    .axles {
        @extend .axlesList;

        button.addAxle {
            letter-spacing: -0.024em;
            font-size: 1.5rem;
            color: #707070;
            margin: 0 1rem;
            text-align: center;
            width: 100%;
            margin: 2rem 0;
            display: flex;
            justify-content: center;
            background: transparent;

            &:before {
                margin-right: .7rem;
                content: '';
                width: 1.5rem;
                height: 1.5rem;
                display: block;
                background-image: url('../../assets/sfpro-plus-circle.png');
                background-size: 1.5rem;
                background-position: center;
                background-repeat: no-repeat;
                border: none;
                position: relative;
                top: 2px;
            }
        }

    }

    .images-list > button {
        background: none;
    }
}
