.settingsView {
    .modalContent {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;

        z-index: map-get($z-indices, settings-logs-modal);
        background-color: #000000c0;
        padding: 5rem;

        pre {
            background-color: map-get($theme-colors, slate);
            color: black;
            height: 100%;
            font-size: 1.5rem;

            overflow-x: scroll;
            overflow-y: scroll;
            position: relative;
        }

        .clickRegion {
            position: fixed;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
        }
    }

    .details {
        dt, dd {
            display: flex;
            flex-direction: column;
            align-items: start;
            justify-content: center;
            margin: 7px 0;
        }
    }

    .refreshInterval .inputWrapper {
        min-width: 20rem;
        margin-left: 0;
        margin-right: 0;

        &.dirty {
            border: 1px solid red;
        }
    }
}
