.dashboard {
    > * {
        margin-bottom: 2rem;
    }

    .errored {
        @extend .errorIndicator;
    }

    .table-container {
        background-color: white;
        border-bottom: 1px solid rgba(60, 60, 60, 0.35);
        border-top: 1px solid rgba(60, 60, 60, 0.35);
        margin-bottom: 1.5rem;
    }

    header {
        border-radius: 0 0 12px 12px;
        background: linear-gradient(rgba(0,0,0, .3), rgba(0,0,0,.3)), url('../../assets/bg-web.jpg');
        background-size: cover;
        color: white;
        margin: 0 1.5rem 15px;
        padding: 2rem 2rem 3rem;
        text-align: left;
        background-position: 40% calc(50% + 3.5rem);
        display: block;

        strong {
            font-size: 3.75rem;
        }
    }

    .recentClients {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        &:after {
            content: '';
            width: 100%;
            height: 1px;
            display: block;
            padding-top: 2rem;
            margin-left: 1.5rem;
            margin-right: 1.5rem;
            border-bottom: 1px solid $border-grey;
        }

        h2 {
            padding: 1rem 0 1rem 1.5rem;
            flex: 1 0 50%;
        }

        .viewAll {
            flex: 1 0 50%;
            display: inline-block;
            padding: 1rem 1.5rem;
            text-align: right;
        }

        > ul {
            margin-left: 1.5rem;
            flex: 2 0 100%;
            display: flex;
            flex-direction: row;
            overflow-x: scroll;
            overflow-y: hidden;
            scrollbar-width: none;

            &::-webkit-scrollbar {
                display: none;
            }

            li {
                height: 7.2rem;
                padding: 1.4rem 2rem 1.4rem 1.6rem;
                margin:0 1.5rem 0 0;
                border: none;
                border-radius: 2rem;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                font-size: 1.7rem;
                background: white;

                font-weight: bold;
                letter-spacing: -0.024em;

                a {
                    color: #707070;
                    width: max-content;
                    max-width: 12rem;
                }

            }
        }
    }

    .assignments {
        display: flex;
        flex-direction: column;

        h2 {
            padding-bottom: 0;
            margin-bottom: 1.5rem;
            padding-top: 0;
        }

        .groupedList {
            .row {
                display: grid;
                grid-gap: 1rem;
                grid-template-columns: 1fr 1fr 4.5rem;
            }
        }

        .inspectionCount,
        .severity {
            margin: 0 0 0 1rem;
        }

        .clientLocation {
            margin-left: 2rem;
            font-size: 1.5rem;
            text-align: right;
            letter-spacing: -0.024em;
            color: $text-grey;
        }
    }

    .message {
        font-size: 1.5rem;
    }
}
