.category-trail {
    margin: 1rem 0;

    a, span {
        color: $text-grey;
    }

    span {
        padding: 0 0.25rem;
    }
}
