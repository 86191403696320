// .inspectionReport header
& {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    border-bottom: 1px solid map-get($theme-colors, misc-grey-light);
    padding-bottom: 5rem;
    margin-bottom: 5rem;

     .logo {
        width: 10rem;
        background-image: url('../../../assets/CFITireLogo-Mark.png');
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        height: 10rem;
        margin-right: 4rem;
    }

    .clientDetail {
        * {
            font-size: 1.8rem;
            // font-style: italic;
        }

        h1 {
            margin: 0.75rem 0;
            font-size: 3.5rem;
        }

        .clientMeta {
            font-size: 1.4rem;
        }
    }

    .inspectionDetail {
        border: 1px solid map-get($theme-colors, misc-grey-light);
        border-radius: 1rem;
        padding: 1.3rem 1.5rem 1.5rem;
        min-width: 20rem;
        margin: 0 0 auto auto;

        dt {
            font-weight: bold;
            color: #666666;
            font-style: italic;

            &:not(:first-child) {
                margin-top: 1rem;
            }
        }
    }

}