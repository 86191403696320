.App.inspectionReportApp {
    display: block;
    height: unset;
    width: unset;
}

.inspection {
    &Report {
        @import './report.scss';

        header {
            @import 'header';
        }

        footer {
            @import 'footer';
        }

        .inspected {
            &Equipment {
                @import 'Equipment';
            }
        }
    }
    
    &Overview {
        @import 'Overview';
    }
}
