//.inspectionReport
& {
    background-color: white;
    display: flex;
    flex-direction: column;
    padding: 4rem;
    margin: 0 auto;
    font-size: 1.4rem;
    width: 100%;

    .severity {
        background-size: 50% 50%;
        background-repeat: no-repeat;
        background-position: center;

        &.Critical {
            background-image: url('../../../assets/white-cross.svg');
        }

        &.Nominal {
            background-image: url('../../../assets/white-plus.svg');
            background-size: 60% 60%;
        }

        &.Warning {
            background-image: url('../../../assets/white-minus.svg');
        }
    }

    &--int {
        margin: auto;
        max-width: 90rem;
        width: 100%;
    }

    .arc {
        stroke: white;
        stroke-width: 0.3rem;
    }

    .annotation {
        font-size: 13px;
    }

    .Ignore {
        fill: map-get($theme-colors, misc-grey);
    }
    .Nominal {
        fill: map-get($theme-colors, green);
    }
    .Warning {
        fill: map-get($theme-colors, yellow);
    }
    .Critical {
        fill: map-get($theme-colors, red);
    }

    .severity {
        margin: 0 1rem 0 0;
    }

    .separator:before {
        display: inline-block;
        content: '/';
        padding: 0 1rem;
    }

    h2 {
        font-size: 2.5rem;
        margin: 0;
        background: none;
        color: #666;
    }
    /* END STOPGAP */
}
